// by Waymon 2021 9 1
export function getStorage(key) {
    const value = window.localStorage.getItem(key)
    try {
        return JSON.parse(window.localStorage.getItem(key))
    } catch (error) {
        return value
    }
}

export function setStorage(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value))
}

export function removeStorage(key) {
    window.localStorage.removeItem(key)
}

export function getSession(key) {
    const value = window.sessionStorage.getItem(key)
    try {
        return JSON.parse(window.sessionStorage.getItem(key))
    } catch (error) {
        return value
    }
}

export function setSession(key, value) {
    window.sessionStorage.setItem(key, JSON.stringify(value))
}

export function removeSession(key) {
    window.sessionStorage.removeItem(key)
}

export const pathTitle = {
    Login: '登录',
    Home: '首页',
    Admin: '管理员列表',
    Author: '权限管理',
    City: '城市列表',
    Member: '用户列表',
    Metarial: '资料',
    Statics: '用户画像',
    Search: '搜索统计',
    Setting: '系统设置'
}

import http from "@/utils/http";


export function HomeIndex(params) {
    return  http({
        url: "home/index",
        method: "get",
        params: params
    })
}
export function HomeMenu(params) {
    return  http({
        url: "home/menu",
        method: "get",
        params: params
    })
}

import {
  createRouter,
  createWebHashHistory
} from 'vue-router'

const routes = [{
  path: '/',
  redirect: '/Home'
}, {
  path: '/Home',
  name: 'Home',
  component: () =>
      import ('../views/Home.vue')
}, {
  path: '/Login',
  name: 'Login',
  component: () =>
      import ('../views/Login.vue')
}, {
  path: '/Member',
  name: 'Member',
  component: () =>
      import ('../views/Member.vue')
}, {
  path: '/Metarial',
  name: 'Metarial',
  component: () =>
      import ('../views/Metarial.vue'),
  meta: {
    keepAlive: true
  }
},{
  path: '/Buy',
  name: 'Buy',
  component: () =>
      import ('../views/Buy.vue')
},{
  path: '/Love',
  name: 'Love',
  component: () =>
      import ('../views/Love.vue')
}, {
  path: '/Static',
  name: 'Static',
  component: () =>
      import ('../views/Static.vue')
}, {
  path: '/Search',
  name: 'Search',
  component: () =>
      import ('../views/Search.vue')
}, {
  path: '/City',
  name: 'City',
  component: () =>
      import ('../views/City.vue')
}, {
  path: '/CityAdd',
  name: 'CityAdd',
  component: () =>
      import ('../views/CityAdd.vue'),
  meta: {
    from: 'City',
    title: '添加城市'
  }
}, {
  path: '/CityEdit',
  name: 'CityEdit',
  component: () =>
      import ('../views/CityEdit.vue'),
  meta: {
    from: 'City',
    title: '编辑城市'
  }
},{
  path: '/Admin',
  name: 'Admin',
  component: () =>
      import ('../views/Admin.vue')
}, {
  path: '/AdminAdd',
  name: 'AdminAdd',
  component: () =>
      import ('../views/AdminAdd.vue'),
  meta: {
    title: '新增管理员',
    from: 'Admin'
  }
}, {
  path: '/AdminEdit',
  name: 'AdminEdit',
  component: () =>
      import ('../views/AdminEdit.vue'),
  meta: {
    title: '管理员编辑',
    from: 'Admin'
  }
}, {
  path: '/MemberEdit',
  name: 'MemberEdit',
  component: () =>
      import ('../views/MemberEdit.vue'),
  meta: {
    from: 'Member',
    title: '用户编辑'
  }
}, {
  path: '/MetarialImage',
  name: 'MetarialImage',
  component: () =>
      import ('../views/MetarialImage.vue'),
  meta: {
    from: 'Metarial',
    title: '资料照片'
  }
}, {
  path: '/Vip',
  name: 'Vip',
  component: () =>
      import ('../views/Vip.vue')
}, {
  path: '/Order',
  name: 'Order',
  component: () =>
      import ('../views/Order.vue')
},{
  path: '/Report',
  name: 'Report',
  component: () =>
      import ('../views/Report.vue')
},{
  path: '/File',
  name: 'File',
  component: () =>
      import ('../views/File.vue')
},{
  path: '/FileCash',
  name: 'FileCash',
  component: () =>
      import ('../views/FileCash.vue')
},{
  path: '/Role',
  name: 'Role',
  component: () =>
      import ('../views/Role.vue')
},{
  path: '/RoleAdd',
  name: 'RoleAdd',
  component: () =>
      import ('../views/RoleAdd.vue')
},{
  path: '/RoleEdit',
  name: 'RoleEdit',
  component: () =>
      import ('../views/RoleEdit.vue')
}, ]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

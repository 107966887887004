<template>
  <div class="app">
    <el-container v-if="!state.isLogin" class="container">
      <el-aside class="aside" width="200px">
        <div class="aside-top">
          <img src="./assets/logo.png">
          <div>正在爱管理系统</div>
        </div>
        <el-menu
            v-if="state.menus"
            :default-opends="['1']"
            background-color="#222832"
            text-color="#fff"
            active-text-color="#29AEAD"
            :router="true"
            :unique-opened="true"
            :default-active="state.currentPath"
        >
          <el-sub-menu v-for="(item,index) in state.menus" :index=" index+1 ">
            <template #title>
              <el-icon v-if="item.id==1"><home-filled /></el-icon>
              <el-icon v-else-if="item.id==2"><opportunity /></el-icon>
              <el-icon v-else-if="item.id==20"><user-filled /></el-icon>
              <el-icon v-else-if="item.id==30"><list /></el-icon>
              <el-icon v-else-if="item.id==40"><flag /></el-icon>
              <el-icon v-else-if="item.id==60"><comment /></el-icon>
              <el-icon v-else-if="item.id==70"><grid /></el-icon>
              <el-icon v-else-if="item.id==80"><histogram /></el-icon>
              <el-icon v-else-if="item.id==90"><help-filled /></el-icon>

              <span>{{ item.title }}</span>
            </template>
            <el-menu-item-group v-if="item.sub" v-for="(value,key) in item.sub">
              <el-menu-item :index="value.index" :title="value.title" :route="value.route">
                {{ value.title }}
              </el-menu-item>
            </el-menu-item-group>
          </el-sub-menu>
        </el-menu>
      </el-aside>
      <el-container v-if="!state.isLogin">
        <el-header class="vue-header">
          <div class="header-left">
            <el-icon class="header-back" :size="20" v-if="state.isBack" @click="onBack"><arrow-left /></el-icon>
            <div class="title">{{ state.title }}</div>
          </div>
          <div class="header-right">
            <el-badge :value="1" class="item">
              <el-icon size="18">
                <bell-filled/>
              </el-icon>
            </el-badge>
            <el-dropdown class="right-down" trigger="click" @command="handleCommand">
                 <span class="dropdown-item">
                    {{ state.admin.nick_name }}
                 </span>
              <template #dropdown>
                <el-dropdown-item command="edit">修改密码</el-dropdown-item>
                <el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
              </template>
            </el-dropdown>
            <el-icon size="18" style="margin-left: 5px">
              <avatar/>
            </el-icon>
          </div>
        </el-header>
        <el-main class="main" v-if="!state.isLogin">
          <router-view v-if="isRouterActive" v-slot="{ Component }">
            <keep-alive>
              <component :is="Component"  v-if="$route.meta.keepAlive"/>
            </keep-alive>
            <component :is="Component"  v-if="!$route.meta.keepAlive"/>
          </router-view>
        </el-main>
        <el-footer class="footer">CopyRight @2021 正在爱 后台管理系统 by Waymon</el-footer>
      </el-container>
    </el-container>
    <!-- 登录 -->
    <el-container class="container_main" style="width: 100%;height:100vh" v-else>
      <router-view/>
    </el-container>
  </div>
  <el-backtop/>
</template>
<script setup>
import { ref, reactive, nextTick, provide, onMounted, onUnmounted } from 'vue'
import { useRouter} from 'vue-router'
import { ElNotification, ElBadge } from 'element-plus'
import { BellFilled, Avatar,HomeFilled,Opportunity,UserFilled,
  List,Flag, CircleCheckFilled,Comment,Grid,
  Histogram,HelpFilled,Tools,ArrowLeft } from '@element-plus/icons-vue'
import {getSession, removeStorage} from './utils/util.js'
import {HomeMenu} from "@/apis/home";
const router = useRouter()
const isRouterActive = ref(true)
const state = reactive({
  isLogin: false,
  isBack: false,
  title: '首页',
  admin: {},
  menus: [],
  indexs: [],
  currentPath: 'Home',
})
onMounted(() => {
  const admin = getSession("admin")
  if (!admin) {
    state.isLogin = true
    router.push({ path: '/Login' })
  } else {
    state.isLogin = false
    state.admin = admin
    ElNotification({
      title: '欢迎' + admin.nick_name + '登录!',
      dangerouslyUseHTMLString: true,
      message: '登录时间 : ' + admin.last_time + '</br>' + '登录ip : ' + admin.last_ip + '</br>' + '登录次数 : ' + admin.logins,
      position: 'bottom-right',
    })
    HomeMenu({
      role_id: admin.role_id
    }).then(res=>{
      state.menus = res.data
    })
  }
  router.afterEach((to) => {
    const { title } = to.meta
    state.title = title
    state.isBack = true
  })
})
const onBack = () => {
  router.go(-1)
}
const reload = () => {
  isRouterActive.value = false
  nextTick(() => {
    isRouterActive.value = true
  })
}
provide('reload', {
  reload
})
const handleCommand = (command) => {
  if (command == 'edit') {
    router.push({
      path: '/AdminEdit',
      query: { id: state.admin.id }
    })
  } else {
    removeStorage('admin')
    state.isLogin = true
    router.push({ path: '/Login' })
  }
}
const logout = () => {
  removeStorage('admin')
  state.isLogin = true
  router.push({ path: '/Login' })
}
const unwatch = router.beforeEach((to, from, next) => {
  if (to.path == '/Login') {
    state.isLogin = true
    next()
  } else {
    const admin = getSession("admin")
    if (!admin) {
      state.isLogin = true
      next({ path: '/Login' })
    } else {
      state.isLogin = false
      next()
    }
  }
})
onUnmounted(() => {
  unwatch()
})
</script>
<style lang="scss">
body, html {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  color: #fff;
}

.app {
  min-height: 100vh;
  background-color: #fff;
}

.container {
  width: 100%;
  height: 100vh;
}

.aside {
  width: 200px;
  height: 100%;
  background: #222832;
}

.aside-top {
  display: flex;
}

.aside-top div {
  height: 40px;
  line-height: 40px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 800;
}

.aside-top img {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  margin: 10px;
}

.vue-header {
  width: 100%;
  height: 60px;
  background: #222832;
  display: flex;
  position: relative;
}

.header-left {
  width: 85%;
  display: flex;
}

.header-back {
  margin-top: 20px;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid #fff;
}

.title {
  margin-left: 10px;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
}

.header-right {
  margin-top: 20px;
  height: 40px;
  width: 15%;
  text-align: center;
  display: flex;
}

.right-down {
  margin-left: 20px;
}

.dropdown-item {
  cursor: pointer;
  font-size: 18px;
  color: #fff;
}

.footer {
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: #222832;
  font-size: 15px;
}

.aside-bottom {
  margin-top: 20px;
  width: 200px;
  text-align: center;
}
</style>

import {
    createApp
} from 'vue'

import {
    ElContainer,
    ElMenu,
    ElButton,
    ElPopover,
    ElCard,
    ElBreadcrumb,
    ElInput,
    ElSelect,
    ElTable,
    ElPagination,
    ElForm,
    ElImage,
    ElUpload,
    ElDatePicker,
    ElLoading,
    ElTag,
    ElDrawer,
    ElSlider,
    ElRadio,
    ElTree
} from 'element-plus'
import App from './App.vue'
import router from './router'
import store from './store'

import 'element-plus/dist/index.css'

const app = createApp(App)

app.use(store)
    .use(router)

app.use(ElContainer)
    .use(ElMenu)
    .use(ElButton)
    .use(ElPopover)
    .use(ElCard)
    .use(ElBreadcrumb)
    .use(ElInput)
    .use(ElSelect)
    .use(ElTable)
    .use(ElPagination)
    .use(ElForm)
    .use(ElImage)
    .use(ElUpload)
    .use(ElDatePicker)
    .use(ElLoading)
    .use(ElTag)
    .use(ElSlider)
    .use(ElDrawer)
    .use(ElRadio)
    .use(ElTree)

app.mount('#app')
